<template>
    <section class="bg-blue-light pad-y-60 text-center">
      <div class="container">
        <div class="heading" data-aos="fade-down">
          <h2 class="title font-secondary fs-40 text-teal-dark">Om Swastyastu</h2>
        </div>

        <p class="font-weight-lighter font-italic mar-bottom-25" data-aos="fade-right" data-aos-delay="300">
          Atas Asung Kertha Wara Nugraha Ida Sang Hyang Widhi  Wasa / Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i, pada acara Pawiwahan (Pernikahan) putra-putri kami pada :
        </p>

        <div class="row">
          <div class="col-md-4 text-center mar-bottom-25" data-aos="fade-right" data-aos-delay="500">
            <div>
              <img src="@/assets/icons/calendar.svg">
              <div class="text-uppercase text-muted font-weight-lighter mt-3">
                HARI / TANGGAL
              </div>
              <div class="font-weight-bold fs-18">{{ $parent.time.start.format('dddd, DD MMMM YYYY') }}</div>
            </div>
          </div>
          <div class="col-md-4 text-center mar-bottom-25" data-aos="fade-down" data-aos-delay="500">
            <div>
              <img src="@/assets/icons/clock.svg">
              <div class="text-uppercase text-muted font-weight-lighter mt-3">
                WAKTU
              </div>
              <div class="font-weight-bold fs-18">{{ $parent.time.start.format('HH.mm') }} WITA - {{ $parent.time.end }}</div>
            </div>
          </div>
          <div class="col-md-4 text-center mar-bottom-25" data-aos="fade-left" data-aos-delay="500">
            <div>
              <img src="@/assets/icons/marker.svg">
              <div class="text-uppercase text-muted font-weight-lighter mt-3">
                Lokasi
              </div>
              <div class="font-weight-bold fs-18" v-html="$parent.location"></div>
            </div>
          </div>
        </div>

        <p class="font-weight-lighter font-italic" data-aos="fade-left" data-aos-delay="600">
          Merupakan suatu kehormatan dan kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dan memberikan doa restu kepada putra-putri kami. Atas kehadiran serta doa restu Bapak/Ibu/Saudara/i, kami sekeluarga mengucapkan terima kasih. Kami yang berbahagia.
        </p>

        <h3 class="font-secondary text-teal-dark mb-0 mar-top-25" data-aos="fade-up" data-aos-delay="700">{{ $parent.wedding.man.surname }} & {{ $parent.wedding.woman.surname }}</h3>
      </div>
    </section>
</template>

<script>
export default {

}
</script>